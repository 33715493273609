import * as React from 'react';
import {Card, CardMedia, Typography, CardContent} from '@mui/material';

export default function App(props) {
  return (
    <Card sx={{ width: 345, height: '100%' }}>
      <CardMedia
        component="img"
        alt="green iguana"
        image={props.img}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography gutterBottom variant='h6' component="h6">
            {props.title}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            {props.subtitle}
          </Typography> */}
        </div>
        {/* <CardActions sx={{display: 'block', my:0 }}>
          <Button size="small" variant='contained'>Learn More</Button>
        </CardActions> */}
      </CardContent>
    </Card>
  );
}
