import * as React from "react";

import img1 from '../../assets/pexels-daniel-frank-305565 (1).jpg'
import img2 from '../../assets/pexels-breakingpic-3188.jpg'
import img3 from '../../assets/pexels-karolina-grabowska-4386467.jpg'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./app.css";

// import required modules
import { Autoplay, Navigation } from "swiper";
import { Box } from "@mui/system";

export default function App() {
    return (
        <Box sx={{ position: 'relative' }} className='swiper-container' id="home">
            <Swiper navigation={true}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={30}
                mousewheel={false}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Navigation, Autoplay]}
            >
                <SwiperSlide>
                    <img src={img1} alt='img 1' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={img2} alt='img 2' />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={img3} alt='img 2' />
                </SwiperSlide>
            </Swiper>
        </Box>
    );
}
