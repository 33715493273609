import * as React from 'react'
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import NavbarComponent from './components/navbar/App'
import CarouselComponent from './components/carousel/App'
import AboutComponent from './components/about/App'
import FooterComponent from './components/footer/App'

const theme = createTheme({
  palette: {
    primary: {
      main: "#91373d",
    },
    secondary: {
      main: "#eeeeee",
    },
    dark: {
      main: "#f8f9fa",
    },
    tertiary: {
      main: " #f47236",
    },
    quaternary: {
      main: "#e4164f",
    },
  },
});

let responsiveTheme = responsiveFontSizes(theme);

export default function App() {
  return (
    <>
      <ThemeProvider theme={responsiveTheme}>
        <NavbarComponent />
        <CarouselComponent />
        <AboutComponent />
        <FooterComponent/>
      </ThemeProvider>
    </>
  )
}