import { Typography, Container, Box, Button } from '@mui/material'
import * as React from 'react'
import CardListing from '../card/CardListing'
import TrainingList from '../training/Listing'
import EventList from '../event/listing'

export default function App() {
    return (
        <>
            <Container sx={{ py: 3 }} id="aboutus">
                <Typography component='h4' variant='h4' color='primary' sx={{
                    my: { xs: 3, md: 5 },
                    fontWeight: '700',
                    textAlign: 'center'
                }}>
                    About Us
                </Typography>
                <Typography component='h6' variant='h6' sx={{
                    textAlign: 'start'
                }}>
                    We provide
                    <ul>
                        <li>
                            A platform to connect affordable medical treatments through various, accredited service providers throughout  the globe.
                        </li>
                        <li>
                            Various Healthcare Business support systems.
                        </li>
                        <li>
                            We help  to empower you as effective communicator and leader by developing Communication skills, Leadership skill and Public Speaking.
                        </li>
                        <li>
                            Creative event organising platform to make your event memorable.
                        </li>
                    </ul>
                </Typography>
            </Container>
            <Container id="services">
                <Typography component='h4' variant='h4' color='primary' sx={{
                    my: { xs: 3, md: 5 },
                    fontWeight: '700',
                    textAlign: 'center'
                }}>
                    Services
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography component='h4' variant='h6' color='primary' sx={{
                        my: { xs: 3, md: 5 },
                        fontWeight: '700',
                        textAlign: 'center'
                    }}>
                        Healthcare Management:
                    </Typography>
                    <Button variant="outlined" href="http://www.gtsmeditour.com/" sx={{ textAlign: 'center' }} size='small' >
                        Explore More
                    </Button>
                </Box>
                <CardListing />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography component='h3' variant='div' color='primary' sx={{
                        my: { xs: 3, md: 5 },
                        fontWeight: '700',
                        textAlign: 'center'
                    }}>
                        Training :
                    </Typography>
                    {/* <Button variant="outlined" href="http://www.gtsmeditour.com/" size='small'>
                        Explore More
                    </Button> */}
                </Box>
                <TrainingList />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography component='h3' variant='div' color='primary' sx={{
                        my: { xs: 3, md: 5 },
                        fontWeight: '700',
                        textAlign: 'center'
                    }}>
                        Events :
                    </Typography>
                    {/* <Button variant="outlined" href="http://www.gtsmeditour.com/" size='small'>
                        Explore More
                    </Button> */}
                </Box>
                <EventList />
            </Container>
        </>
    )
}