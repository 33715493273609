import * as React from "react";
import { Box } from "@mui/material";
import img1 from '../../assets/Connecting service providers .jpg'
import img2 from '../../assets/Medical value travel app.webp'
import img3 from '../../assets/iT support.jpg'
import img4 from '../../assets/Knowledge sharing Pic.jpg'
import img5 from '../../assets/JVs .jpg'
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./App.css";

// import required modules
import { Pagination } from "swiper";

import CardComponent from './App'
const data = [
    { img: img1, title: 'Connecting Patients and the Service Providers', subtitle: 'For healthcare management u can take some pics from here .It’s same my partner site' },
    { img: img2, title: 'Medical Value Travel (MVT) Business App', subtitle: 'For healthcare management u can take some pics from here .It’s same my partner site' },
    { img: img3, title: 'IT Support', subtitle: 'For healthcare management u can take some pics from here .It’s same my partner site' },
    { img: img4, title: 'Knowledge Sharing Activities ', subtitle: 'For healthcare management u can take some pics from here .It’s same my partner site' },
    { img: img5, title: 'JV’s', subtitle: 'For healthcare management u can take some pics from here .It’s same my partner site' },
]


export default function App() {
    return (
        <>
            <Box sx={{ position: 'relative', height: '300px' }}>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >

                    {data.map((items, index) => {
                        return <SwiperSlide style={{height:'98%'}}  key={index} >
                            <CardComponent title={items.title} subtitle={items.subtitle} img={items.img} />
                        </SwiperSlide>
                    })}

                </Swiper>
            </Box>
        </>
    );

}


