import { Box } from "@mui/material";
import * as React from "react";
import EventCard from './App'
import One from '../../assets/One.webp'
import Two from '../../assets/Two.webp'
import Three from '../../assets/Three.webp'
import Four from '../../assets/Four.webp'

const data = [
    { title: 'Medical Events', subtitle: 'BizConnect Event', thumb: One, video: 'https://www.youtube.com/embed/tG9Kkb03hyM' },
    { title: 'Medical Seminars', subtitle: 'BizConnect Event', thumb: Two, video: 'https://www.youtube.com/embed/dkpQA7KEOsI' },
    { title: 'Trade Shows', subtitle: 'BizConnect Event', thumb: Three, video: 'https://www.youtube.com/embed/0ZOX2K-6CyI' },
    { title: 'Health Care Service Providers Meet', subtitle: 'BizConnect Event', thumb: Four, video: 'https://www.youtube.com/embed/RDMTAXxiYJk' },
]


export default function App() {
    return (
        <>
            <Box sx={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                {data.map((items, index) => {
                    return (
                        <EventCard title={items.title} subtitle={items.subtitle} thumb={items.thumb} video={items.video} key={index} />
                    )
                })}
            </Box>
        </>
    );

}


