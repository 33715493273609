import logo from '../../logo.jpeg';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { Box, Divider, Drawer, ListItem, ListItemButton, List, IconButton, ListItemText, Button, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'Gallery', 'Services', 'About Us', 'Contact Us'];

function App(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box sx={{ my: 2 }}>
        <img src={logo} alt='logo' style={{ maxWidth: '100px' }} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton  href={`#${item.toLowerCase().replace(/ +/g, "")}`} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" sx={{ backgroundColor: 'white', boxShadow: '1' }}>
          <Toolbar>
            <Box
              sx={{ flexGrow: 1, }}
            >
                <Button href='#home' sx={{ my:0, py:0, boxShadow:0 }} variant="link">
                <img src={logo} alt='logo' style={{ maxWidth: '80px' }} />
                </Button>
            </Box>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' }}}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
                <Button  href={`#${item.toLowerCase().replace(/ +/g, "")}`} key={item} sx={{ color: 'secondary' }}>
                  {item}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default App;
