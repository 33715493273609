import * as React from 'react'
import { Box, Typography, Grid, TextField, Button, Container } from '@mui/material';
// import handClick from './Email';
import SendEmail from './Email';
import SendIcon from '@mui/icons-material/Send';

export default function App() {
    const handSend = (event) => {
        event.preventDefault(); 
        const data = new FormData(event.currentTarget);
        SendEmail(data.get('firstName'),data.get('lastName'),data.get('email'),data.get('number'),data.get('message'))
        document.getElementById('contactForm').reset()
    }
    return (
        <div style={{ backgroundColor: '#91373d', marginTop: '3em' }} id="contactus">
            <Container sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', py: 5 }}>
                <Box sx={{ width: { xs: '90%', md: "50%" } }}>
                    <Typography color='white' component='h6' variant='h3' sx={{ fontWeight: '700', m: 0 }}>
                        Contact Us
                    </Typography>
                    <Typography component='div' color='#fff' variant='p' sx={{ my: 2, ml: 1 }}>
                        <strong>GTS -TRIONET WLL</strong><br />
                        K City Business Center<br />
                        Office No 307 ,Building 75 ,Road no 3201 <br />
                        Road 3201 ,Block 332   Zinj<br />
                        Kingdom of Bahrain<br />
                    </Typography>
                    <Typography component='div' color='#fff' variant='p' sx={{ my: 1, ml: 1 }}>
                        <strong>Mobile:</strong> +973 36003752 , +973 34025572
                    </Typography>
                    <Typography component='div' color='#fff' variant='p' sx={{ ml: 1 }}>
                        <strong>E Mail :</strong> Info@gtstrionet.com
                    </Typography>
                </Box>
                <Box component="form" id="contactForm" onSubmit={handSend} sx={{ mt: 3, width: { xs: '90%', md: "50%" }, boxShadow: 2, p: 3, borderRadius: 2, backgroundColor: 'rgba(255,255,255,0.5)' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                sx={{ backgroundColor: 'inherit' }}
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="email"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="number"
                                label="Mobile"
                                type="text"
                                id="number"
                                autoComplete='off'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="message"
                                name="message"
                                label="Message"
                                multiline
                                rows={4}
                                fullWidth
                                autoComplete='off'
                            />
                        </Grid>
                    </Grid>
                    {/* <p className='my-0'>Successfully delivered</p> */}
                    <Button variant="contained" type='submit' sx={{ mt: 3, mb: 2 }} endIcon={<SendIcon />} >Send</Button>
                </Box>
            </Container>
            <p style={{margin:'0', textAlign:'center',padding:"10px 0",color:'#fff'}}>Copyright © 2022 GTS -TRIONET WLL - All Rights Reserved</p>
        </div>
    )
}