
// export default function handClick(firstname,lastname,email,number,message) {
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({
//     "to": "info@gtstrionet.com",
//     "subject": `Contacted by ${firstname}`,
//     "text": `Name: ${firstname} ${lastname},
//              Email: ${email},
//              Message: ${message},
//              Phone: ${number}
//              `,
//     "html": `
//     <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
//     <tr>
//     <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
//     <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
//   </tr>
//     <tr>
//       <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
//       <td style="border: 1px solid #ddd;padding: 8px;">${firstname} ${lastname}</td>
//     </tr>
//     <tr>
//       <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
//       <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
//     </tr>
//     <tr>
//       <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Phone Number</td>
//       <td style="border: 1px solid #ddd;padding: 8px;">${number}</td>
//     </tr>
//     <tr>
//       <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
//       <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
//     </tr>
//   </table>

//     `
//   });

//   var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: raw,
//     redirect: 'follow'
//   };

//   fetch("https://asia-south1-biz-contact-form-8001a.cloudfunctions.net/sendMail", requestOptions)
//     .then(response => response.text())
//     .then(result => console.log(result))
//     .catch(error => console.log('error', error));



// }
export default function SendEmail(firstname, lastname, email, number, message) {
  window.Email.send({
    SecureToken: "05cf02b4-20b7-43a2-9a02-9ab1d879daa4",
    Host: "smtp.elasticemail.com",
    Username: "info@gtstrionet.com",
    Password: "F8F94898EFF2C8E6078DA3189797258DC2AD",
    To: 'roshan@gtstrionet.com',
    From: "info@gtstrionet.com",
    Subject: `Response by ${firstname}`,
    Body: `  
    <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
         <tr>
         <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
         <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
       </tr>
         <tr>
           <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
           <td style="border: 1px solid #ddd;padding: 8px;">${firstname} ${lastname}</td>
         </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
        </tr>
        <tr>
      <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Phone Number</td>
      <td style="border: 1px solid #ddd;padding: 8px;">${number}</td>
    </tr>
         <tr>
           <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
           <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
         </tr>
       </table>`
  }).then(
    message => console.log(message)
  );
}