import * as React from "react";
import { Box } from "@mui/material";
import img1 from '../../assets/244483874_4582026511860599_5267936532264964584_n-removebg-preview.png'
import img2 from '../../assets/pexels-luis-quintero-2774556.jpg'
import img3 from '../../assets/306357134_5360923747277978_1867698076270134925_n-removebg-preview.png'
import TrainingCard from './App'
const data = [
  {title: 'Communication and Leadership Skills', description:'For healthcare management u can take some pics from here .It’s same my partner site', img:img1},
  {title: 'Career Guidance', description:'For healthcare management u can take some pics from here .It’s same my partner site', img:img2},
  {title: 'Public Speaking', description:'For healthcare management u can take some pics from here .It’s same my partner site', img:img3}
]


export default function App() {
    return (
        <>
            <Box sx={{ display:'flex',flexWrap:'wrap', justifyContent:'center'}}>
                {data.map((items, index) => {
                    return (
                        <TrainingCard title={items.title} description={items.description} key={index} img={items.img}/>)
                })}
            </Box>
        </>
    );

}


