import * as React from 'react';
import {Box, Card, CardContent, CardMedia, Typography, Modal, Backdrop,Fade} from '@mui/material';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs:'376px',md:'600px'},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
};



export default function MediaControlCard(props) {
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  

    return (
        <>
            <Card sx={{width:{xs:'400px',md:'auto'}, display: 'flex',justifyContent:'space-between', m:2, height:'100px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column',  }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h5">
                            {props.title}
                        </Typography>
                        {/* <Typography variant="subtitle1" color="text.secondary" component="div">
                            {props.subtitle}
                        </Typography> */}
                    </CardContent>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                        <IconButton aria-label="play/pause" onClick={handleOpen}>
                            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                        </IconButton>
                    </Box> */}
                </Box>
                <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={props.thumb}
                    alt="Live from space album cover"
                    allow="autoPlay"
                />
            </Card>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <CardMedia
                            component="iframe"
                            sx={{ width: '100%',height:300, border:0 }}
                            image={props.video}
                            alt="Live from space album cover"
                            allow="autoPlay"
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}
